html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Barlow', sans-serif;
  font-size: 62.5%;
}

html,
body,
#root {
  min-height: 100vh;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

a {
  color: currentColor;
  text-decoration: none;
}

input {
  padding: 0;
  border: none;
  background: none;
}

input, textarea, select, button {
  font-family: 'Barlow', sans-serif;
}

button {
  cursor: pointer;
  padding: 0;
  border: none;
  background: none;
}

button:focus,
textarea:focus,
input:focus {
  outline: none;
}

.swiper-container {
  padding-bottom: 15px;
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiper-pagination-bullet {
  width: 7px;
  height: 7px;
  background-color: #e5e5e5;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ccc;
}


@media only screen and (max-width: 768px) {
  html,
  body {
    font-size: 50%;
  }
}