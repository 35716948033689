@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Bold.otf') format('otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-SemiBold.otf') format('otf');
  font-weight: 600;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Medium.otf') format('otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Regular.otf') format('otf');
  font-weight: 400;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Light.ttf') format('ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-Light.ttf') format('ttf');
  font-weight: 300;
}

@font-face {
  font-family: 'Barlow';
  src: url('../fonts/Barlow-LightItalic.ttf') format('ttf');
  font-weight: 300;
  font-style: italic;
}